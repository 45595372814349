<template>
    <CCard>
        <!--        <CCardHeader>-->
        <!--            <CIcon name="cil-filter"/>-->
        <!--            Filter (<strong>{{getCurrentTwitterTweetsMeta.totalCount}}</strong> Tweets<span v-if="getCurrentTwitterTweetsMeta.count != getCurrentTwitterTweetsMeta.totalCount"> / {{getCurrentTwitterTweetsMeta.count}} preloaded</span>)-->
        <!--        </CCardHeader>-->
        <CCardBody>
            <CRow>
                <CCol sm="4">
                    <CInput placeholder="Query String"
                            :value="queryString"
                            v-on:update:value="updateQueryString"
                            :lazy="true"
                    />
                </CCol>
                <CCol sm="4">
                    <CMultiSelect
                            v-on:update="updateSelectedUsers"
                            :key="userFilterKey"
                            :options="allUsers"
                            :selected.sync="selectedUsers"
                            :select="true"
                            search
                            searchPlaceholder="Users"
                            selectionType="tags"
                    >
                    </CMultiSelect>
                </CCol>
                <CCol sm="4">
                    <CMultiSelect
                            v-on:update="updateSelectedTags"
                            :key="tagFilterKey"
                            :options="allTags"
                            :selected="selectedTags"
                            search
                            searchPlaceholder="Tags"
                            selectionType="tags"
                    >
                    </CMultiSelect>
<!--                    <TagSelect v-model="selectedTags"/>-->
                </CCol>
            </CRow>
            <CRow>
                <CCol class="col-auto">
                    <CInputCheckbox
                            label="Deleted Tweets only"
                            inline
                            :checked="deletedOnly"
                            v-on:update:checked="updateDeletedOnly"
                    />
                </CCol>
                <CCol sm="4">
                    <CInputCheckbox
                            label="Include direct Retweets"
                            inline
                            :checked="includeRetweets"
                            v-on:update:checked="updateIncludeRetweets"
                    />
                </CCol>
            </CRow>
        </CCardBody>
        <CCardFooter>
            <CIcon name="cil-filter"/>
            Results: <slot v-if="getCurrentTweetsLoading"><CSpinner color="twitter" size="sm" grow class="mr-2"/> </slot><strong>{{getCurrentTwitterTweetsMeta.totalCount}}</strong> Tweets
            <span v-if="getCurrentTwitterTweetsMeta.count != getCurrentTwitterTweetsMeta.totalCount"> • {{getCurrentTwitterTweetsMeta.count}} preloaded</span>
<!--            • preloaded Daterange:-->
        </CCardFooter>
        <CElementCover v-if="getCurrentTweetsLoading"/>
    </CCard>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import TagSelect from "./TagSelect";

    export default {
        name: "TweetFilter",
        components:{
            TagSelect
        },
        data() {
            return {
                selectedTags: [],
                tagFilterKey: 0,
                userFilterKey: 0,
                tableFilterValue: ''
            }
        },
        watch: {
            tableFilterValue: function (val) {
                this.updateCurrentTweetsFilter({query: val})
            }
        },
        methods: {
            ...mapActions([
                'requestCurrentTwitterTweets',
                'requestCurrentTwitterUsers',
                'updateCurrentTweetsFilter',
                'requestCurrentTwitterTags',
            ]),
            updateSelectedTags: function (val) {
                this.selectedTags = val;
                let newTagSelectionNames = [];
                this.allTags.forEach(element => {
                    if (this.selectedTags.includes(element.value)) {
                        newTagSelectionNames.push(element.text);
                    }
                });
                this.updateCurrentTweetsFilter({tags: newTagSelectionNames.join()})
                console.log(this.selectedTags, newTagSelectionNames.join())
            },
            updateSelectedUsers(selected) {
                let user_ids_str = selected.join();
                this.updateCurrentTweetsFilter({user_ids: selected.length > 0 ? user_ids_str : null})
            },
            addSelectedUser(user_id) {
                let curSelectedUsers = this.selectedUsers;
                if (!curSelectedUsers.includes(user_id)) {
                    curSelectedUsers.push(user_id);
                    this.updateSelectedUsers(curSelectedUsers);
                    this.userFilterKey = this.userFilterKey + 1;  //hack to update the component data
                }
            },
            updateDeletedOnly: function (val) {
                this.updateCurrentTweetsFilter({deleted: val ? 1 : 0})
            },
            updateIncludeRetweets: function (val) {
                this.updateCurrentTweetsFilter({include_retweets: val ? 1 : 0})
            },
            updateQueryString: function (val) {
                this.updateCurrentTweetsFilter({query: val.length > 1 ? val : null})
            },
        },
        computed: {
            ...mapGetters([
                'getCurrentTwitterTweetsMeta',
                'getCurrentTweetsFilter',
                'getCurrentTwitterUsers',
                'getCurrentTwitterTags',
                'getCurrentTweetsLoading',
            ]),
            deletedOnly() {
                return !!this.getCurrentTweetsFilter.deleted
            },
            includeRetweets() {
                return !!this.getCurrentTweetsFilter.include_retweets
            },
            queryString() {
                return this.getCurrentTweetsFilter.query ? this.getCurrentTweetsFilter.query : ''
            },
            selectedUsers() {
                return this.getCurrentTweetsFilter.user_ids ? this.getCurrentTweetsFilter.user_ids.split(',') : []
            },
            allUsers() {
                let users = this.getCurrentTwitterUsers;
                let lusers = [];
                if (users) {
                    users.forEach(user => {
                        lusers.push({
                            value: user.id,
                            text: user.name + ' @' + user.screen_name// + ' (' + user.tweetnumber + ')'
                        })
                    });
                }
                this.userFilterKey = this.userFilterKey + 1; //hack to update the component data
                return lusers
            },
            allTags() {
                let allTags = this.getCurrentTwitterTags;
                let tags = [];
                if (allTags.tags) {
                    allTags.tags.forEach(element => {
                        tags.push({
                            value: element.tag_id,
                            text: element.name
                        })
                    });
                }
                this.tagFilterKey = this.tagFilterKey + 1; //hack to update the component data
                return tags
            },
        },
        created() {
            if(!this.getCurrentTwitterTags.tags){
                this.requestCurrentTwitterTags();
            }
        },
    }
</script>

<style scoped>

</style>
