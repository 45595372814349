<template>
    <div>
        <CRow>
            <CCol col="12">
                <TweetFilter ref="TweetFilter"></TweetFilter>
            </CCol>
            <CCol col="12" class="mb-2 mt-n3">
                <CSwitch size="sm" color="primary" class="pt-2 mr-1" :checked.sync="timelineView"/>
                <label>Timeline view</label>
            </CCol>
            <CCol sm="12" v-if="!timelineView">
                <CDataTable
                        :items="sortedItems"
                        :fields="fields"
                        :items-per-page="100"
                        :items-per-page-select="false"
                        :sorter="{ external: true, resetable: true }"
                        :sorter-value.sync="sorterValue"
                        hover
                        pagination
                        :loading="this.getCurrentTweetsLoading"
                >
                    <template #screen_name="{item, index}">
                        <td @mouseover="showUserActionsByIndex = index" @mouseout="showUserActionsByIndex = null">
                            <div class="text-nowrap" style="max-width: 200px;text-overflow:ellipsis; overflow:hidden">
                                <strong class="mr-2" v-if="getUserFromScreenName(item.screen_name)">{{getUserFromScreenName(item.screen_name).name}}</strong>

                            </div>

                            <em class="mr-2">@{{item.screen_name}}</em>
                            <a @click="addSelectedUser(item.user_id)" style="cursor:pointer;">
                                <CIcon @click="addSelectedUser(item.user_id)" name="cil-filter" class="text-muted" v-c-tooltip="'Filter by this User'"/>
                            </a>
                            <router-link :to="{ name: 'Account', params: { id: item.user_id }}">
                                <CIcon @click="addSelectedUser(item.user_id)" name="cil-user" class="text-muted" v-c-tooltip="'Go to User Details'"/>
                            </router-link>
                            <div>
                                <CBadge href="#" color="dark" v-for="(m, index) in item.tags" :key="index" class="mr-1">{{m.name}}</CBadge>
                            </div>

                        </td>
                    </template>
                    <template #tags="{item}">
                        <td>
                            <CBadge href="#" color="dark" v-for="(m, index) in item.tags" :key="index" class="mr-1">{{m.name}}</CBadge>
                        </td>
                    </template>
                    <template #created="{item}">
                        <td>
                            <div class="small">{{formatDateDistanceToNow(item.created)}}</div>
                            <CBadge :href="'https://twitter.com/'+item.screen_name+'/status/'+item.tweet_id" target="_blank" color="dark" shape="pill">{{formatDateString(item.created)}}</CBadge>
                        </td>
                    </template>
                    <template #deleted="{item}">
                        <td>
                            <div v-if="item.deleted">
                                <div class="small">nach {{formatDateDistance(item.deleted, item.created)}}</div>
                                <CBadge :color="getDeletedBadgeColor(item)" shape="pill">{{formatDateString(item.deleted)}}</CBadge>
                                <br>
                                <a class="small text-nowrap" v-if="item.extras && item.extras.similar_tweet_id" :href="'https://twitter.com/'+item.screen_name+'/status/'+item.extras.similar_tweet_id"
                                   target="_blank">
                                    similar tweet
                                    <CIcon style="width:0.5rem" name="cil-external-link"></CIcon>
                                </a>
                                <!--                                <CBadge v-if="item.extras && item.extras.similar_tweet_id" color="warning" shape="pill" :href="'https://twitter.com/'+item.screen_name+'/status/'+item.extras.similar_tweet_id" target="_blank">{{item.extras.similar_tweet_id}}</CBadge>-->
                            </div>
                        </td>
                    </template>
                    <template #likes="{item}">
                        <td class="text-nowrap">
                            <CIcon name="cil-heart" class="mr-2"></CIcon>
                            <strong v-if="item.likes">{{item.likes}}</strong>{{item.likes ? '' : 0}}
                        </td>
                    </template>
                    <template #retweet="{item}">
                        <td class="text-nowrap">
                            <CIcon name="cil-loop" class="mr-2"></CIcon>
                            <strong v-if="item.retweet">{{item.retweet}}</strong>{{item.retweet ? '' : 0}}
                        </td>
                    </template>
                    <template #quote="{item}">
                        <td class="text-nowrap">
                            <CIcon name="cil-double-quote-sans-left" class="mr-2"></CIcon>
                            <strong v-if="item.quote">{{item.quote}}</strong>{{item.quote ? '' : 0}}
                        </td>
                    </template>
                    <template #reply="{item}">
                        <td class="text-nowrap">
                            <CIcon name="cil-comment-square" class="mr-2"></CIcon>
                            <strong v-if="item.reply">{{item.reply}}</strong>{{item.reply ? '' : 0}}
                        </td>
                    </template>
                    <!--<template #deleted-filter>
                        <CSwitch
                                variant="opposite"
                                size=""
                                color="danger"
                                name="deleted"
                                class="pt-1"
                                :checked.sync="deletedOnly"
                        />
                    </template>
                    <template #tags-filter>
                        <CMultiSelect
                                v-on:update="updateSelectedTags"
                                :key="tagFilterKey"
                                :options="allTags"
                                :selectedItems.sync="selectedTags"
                                search
                                searchPlaceholder="Tags"
                                selectionType="tags"
                        >
                        </CMultiSelect>
                    </template>-->

                    <!--<template #status="{item}">
                        <td>
                            <CBadge :color="getBadge(item.status)">
                                {{item.status}}
                            </CBadge>
                        </td>
                    </template>
                    <template #show_details="{item, index}">
                        <td class="py-2">
                            <CButton
                                    color="primary"
                                    variant="outline"
                                    square
                                    size="sm"
                                    @click="toggleDetails(item, index)"
                            >
                                {{Boolean(item._toggled) ? 'Hide' : 'Show'}}
                            </CButton>
                        </td>
                    </template>
                    <template #details="{item}">
                        <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                            <CCardBody>
                                <CMedia :aside-image-props="{ height: 102 }">
                                    <h4>
                                        {{item.username}}
                                    </h4>
                                    <p class="text-muted">User since: {{item.registered}}</p>
                                    <CButton size="sm" color="info" class="">
                                        User Settings
                                    </CButton>
                                    <CButton size="sm" color="danger" class="ml-1">
                                        Delete
                                    </CButton>
                                </CMedia>
                            </CCardBody>
                        </CCollapse>
                    </template>-->
                </CDataTable>
            </CCol>
            <CCol sm="12" v-else>
                <div class="text-center">
                    <CSpinner grow v-if="!timeLineItems.length"/>
                </div>
                <CCard v-for="(tweet, key) in timeLineItems" :key="key" :class="getTweetCardClasses(tweet)">
                    <CCardHeader>
                        <div class="d-flex justify-content-between">
                            <div>
                                <strong class="mr-2" v-if="getUserFromScreenName(tweet.screen_name)">{{getUserFromScreenName(tweet.screen_name).name}}</strong>
                                <CIcon class="mr-1" v-if="getUserFromScreenName(tweet.screen_name) && getUserFromScreenName(tweet.screen_name).verified === true" name="cil-check-circle"/>
                                <em class="mr-2">@{{tweet.screen_name}}</em>
                                <a @click="addSelectedUser(tweet.user_id)" style="cursor:pointer;">
                                    <CIcon @click.stop.prevent="addSelectedUser(tweet.user_id)" name="cil-filter" class="text-muted" v-c-tooltip="'Filter by this User'"/>
                                </a>
                                <router-link :to="{ name: 'Account', params: { id: tweet.user_id }}">
                                    <CIcon @click.stop.prevent="addSelectedUser(tweet.user_id)" name="cil-user" class="text-muted" v-c-tooltip="'Go to User Details'"/>
                                </router-link>
                                <small> • {{formatDateDistanceToNow(tweet.created)}}</small>
                            </div>
                            <div class="">
                                <CBadge href="#" color="dark" v-for="(m, index) in tweet.tags" :key="index" class="mr-1">{{m.name}}</CBadge>
                            </div>
                        </div>
                    </CCardHeader>
                    <CCardBody>
                        <div class="d-flex" @click="showTweetDetails(tweet)" style="cursor: pointer">
                            <div class="mr-3">
                                <CImg v-if="getUserFromScreenName(tweet.screen_name) && getUserFromScreenName(tweet.screen_name).json"
                                      :src="getUserFromScreenName(tweet.screen_name).json.profile_image_url" shape="rounded-circle" alt="" width="48" height="48"/>
                            </div>
                            <div>
                                <div style="max-width: 506px">
                                    {{tweet.text}}
                                </div>
                            </div>
                        </div>
                    </CCardBody>
                    <CCardFooter :class="getTweetCardClasses(tweet)">
                        <div class="d-flex justify-content-between">
                            <div>
                                <CIcon name="cil-heart" class="mr-2"></CIcon>
                                <strong v-if="tweet.likes">{{tweet.likes}}</strong>{{tweet.likes ? '' : 0}}
                            </div>
                            <div>
                                <CIcon name="cil-loop" class="mr-2"></CIcon>
                                <strong v-if="tweet.retweet">{{tweet.retweet}}</strong>{{tweet.retweet ? '' : 0}}
                            </div>
                            <div>
                                <CIcon name="cil-double-quote-sans-left" class="mr-2"></CIcon>
                                <strong v-if="tweet.quote">{{tweet.quote}}</strong>{{tweet.quote ? '' : 0}}
                            </div>
                            <div>
                                <CIcon name="cil-comment-square" class="mr-2"></CIcon>
                                <strong v-if="tweet.reply">{{tweet.reply}}</strong>{{tweet.reply ? '' : 0}}
                            </div>
                            <div>
                                <!--                                    <div class="small">erstellt {{formatDateDistanceToNow(tweet.created)}}</div>-->
                                <a class="small text-nowrap mr-2" v-if="tweet.extras && tweet.extras.similar_tweet_id" :href="'https://twitter.com/'+tweet.screen_name+'/status/'+tweet.extras.similar_tweet_id"
                                   target="_blank">
                                    similar tweet
                                    <CIcon style="width:0.5rem" name="cil-external-link"></CIcon>
                                </a>
                                <CBadge color="dark" shape="pill" :href="'https://twitter.com/'+tweet.screen_name+'/status/'+tweet.tweet_id" target="_blank">
                                    {{formatDateString(tweet.created)}}
                                </CBadge>
                            </div>
                        </div>
                    </CCardFooter>
                </CCard>
            </CCol>
            <CModal title="Tweet details" size="xl" :show.sync="tweetDetailsModal">
                <pre>{{tweetDetails}}</pre>
            </CModal>
        </CRow>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import TweetFilter from "./modules/TweetFilter";

    import {format, formatDistanceToNow, formatDistance} from 'date-fns'
    import {de} from 'date-fns/locale'
    import {twitterService} from "../../_services/twitter.service";

    const fields = [
        {key: 'screen_name', _style: 'width:200px'},
        {key: 'text', _style: 'width:510px', sorter: false},
        // {key: 'tags', sorter: false},
        {key: 'likes', _style: 'width:1%'},
        {key: 'retweet', _style: 'width:1%'},
        {key: 'quote', _style: 'width:1%'},
        {key: 'reply', _style: 'width:1%'},
        {key: 'created', _style: 'width:150px'},
        {key: 'deleted', _style: 'width:150px'},
    ];

    const fields_small = [
        {key: 'text', _style: 'width:510px', sorter: false},
        // {key: 'tags', sorter: false},
        {key: 'created', _style: 'width:150px'},
        {key: 'deleted', _style: 'width:150px'},
    ];

    export default {
        name: 'Tweets',
        components: {
            TweetFilter
        },
        data() {
            return {
                fields,
                fields_small,
                details: [],
                collapseDuration: 0,
                sorterValue: {column: null, asc: true},
                cardCollapse: false,
                timelineView: false,
                timeLineCount: 50,
                tweetDetailsModal: false,
                tweetDetails: {}
            }
        },
        methods: {
            ...mapActions([
                'requestCurrentTwitterTweets',
                'requestCurrentTwitterUsers',
                'updateCurrentTweetsFilter',
            ]),
            showTweetDetails(tweet){
                this.tweetDetails = null;
                this.tweetDetailsModal = true;
                twitterService.getTweet(tweet.tweet_id).then(res => {
                    this.tweetDetails = res.data.tweet;
                });
            },
            getUserFromScreenName(screenname) {
                return this.getCurrentTwitterUsers.find(item => {
                    return item.screen_name === screenname
                })
            },
            formatDateDistance(date1, date2) {
                return formatDistance(date1, date2, {locale: de})
            },
            formatDateDistanceToNow(date) {
                return formatDistanceToNow(date, {locale: de, addSuffix: true})
            },
            formatDateString(date) {
                return format(date, 'p, P', {locale: de})
            },
            updateSelectedUsers(selected) {
                return this.$refs.TweetFilter.updateSelectedUsers(selected)
            },
            addSelectedUser(user_id) {
                return this.$refs.TweetFilter.addSelectedUser(user_id)
            },
            toggleDetails(item) {
                this.$set(this.items[item.id], '_toggled', !item._toggled);
                this.collapseDuration = 300;
                this.$nextTick(() => {
                    this.collapseDuration = 0
                })
            },
            getDeletedBadgeColor(tweet) {
                if (tweet.deleted) {
                    if (tweet.extras && tweet.extras.similar_tweet_id) {
                        return "warning"
                    } else {
                        return "danger"
                    }
                }
            },
            getTweetCardClasses(tweet) {
                if (tweet.deleted) {
                    if (tweet.extras && tweet.extras.similar_tweet_id) {
                        return "bg-gradient-warning text-white"
                    } else {
                        return "bg-gradient-danger text-white"
                    }
                }
            },
            scroll() {
                window.onscroll = () => {
                    let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight

                    if (bottomOfWindow) {
                        this.timeLineCount += 20;
                    }
                }
            }
        },
        computed: {
            ...mapGetters([
                'getCurrentTwitterTweetsMeta',
                'getCurrentTwitterTweets',
                'getCurrentTweetsLoading',
                'getCurrentTweetsFilter',
                'getCurrentTwitterUsers'
            ]),
            timeLineItems() {
                return this.getCurrentTwitterTweets.slice(0, this.timeLineCount)
            },
            sortedItems() {
                const col = this.sorterValue.column;
                if (col === null) {
                    return this.getCurrentTwitterTweets
                }
                let flip = this.sorterValue.asc ? 1 : -1;
                if (col === 'likes' || col === 'quote' || col === 'reply' || col === 'retweet') {
                    // invert flip for convenience (TODO: technically the sorting arrows are the wrong way now)
                    flip = -flip
                }
                return this.getCurrentTwitterTweets.slice().sort((item, item2) => {
                    const value = item[col];
                    const value2 = item2[col];
                    let a = 0, b = 0;
                    if (col === 'likes' || col === 'quote' || col === 'reply' || col === 'retweet') {
                        //number sorting with catching null
                        a = value === null ? 0 : value;
                        b = value2 === null ? 0 : value2;
                    } else if (col === 'created') {
                        a = +value; // the `+` converts the datetime to a timestamp number
                        b = +value2;
                    } else if (col === 'deleted') {
                        // calculate time distance between creation an detection
                        const dist1 = value - item['created'];
                        const dist2 = value2 - item2['created'];
                        a = +dist1; // the `+` converts the datetime to a timestamp number
                        b = +dist2;
                    } else {
                        a = String(value).toLowerCase();
                        b = String(value2).toLowerCase();
                    }
                    return a > b ? flip : b > a ? -1 * flip : 0
                })
            },
            selectedUsers() {
                return TweetFilter.computed.selectedUsers()
                //return this.getCurrentTweetsFilter.user_ids ? this.getCurrentTweetsFilter.user_ids.split(',') : []
            },
        },
        created() {
            this.requestCurrentTwitterTweets();
            this.requestCurrentTwitterUsers();
        },
        mounted() {
            this.scroll()
        }
    }
</script>
